import SplunkOtelWeb from '@splunk/otel-web';
import { splunkRumAgentStarter } from '@ticketmaster/tm1pos-web-shared/middleware/OpenTelemetry/splunk';
import { selectCurrentEventDetails } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { selectUserInformation } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { useState } from 'react';
import { useAppSelector } from 'store/hooks';

export const SplunkRumAgent = () => {
  const userInformation = useAppSelector(selectUserInformation);
  const eventDetails = useAppSelector(selectCurrentEventDetails);
  const [tracer, setTracer] = useState<ReturnType<typeof splunkRumAgentStarter>>();
  let currentTracer = tracer;

  if (!SplunkOtelWeb.inited && userInformation) {
    if (!currentTracer) {
      currentTracer = splunkRumAgentStarter('sales.sales-ui', userInformation?.splunkRumToken || '');
      setTracer(currentTracer);
    }

    currentTracer.setUserInformation(userInformation);
    currentTracer.setEventDetails(eventDetails);
  }

  return null;
};
