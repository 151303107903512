import { createSelector } from 'reselect';
import type { RootState } from '../../store';

const selectAppState = (state: RootState) => state.app;
export const selectIsLoadingEventFees = createSelector(selectAppState, (state) => state.get('isLoadingSetEventFees'));

export const selectShowEditOtherChargesModal = createSelector(selectAppState, (state) =>
  state.get('showEditOtherChargesModal'),
);

export const selectShowEditOtherChargesErrorBanner = createSelector(selectAppState, (state) =>
  state.get('showEditOtherChargesErrorBanner'),
);

export const selectShowCartExpiredErrorModal = createSelector(selectAppState, (state) =>
  state.get('showCartExpiredErrorModal'),
);

export const selectDeliveryErrors = createSelector(selectAppState, (state) => state.get('deliveryErrors'));
