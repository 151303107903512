import { isSalesWebPage } from './utils/isSalesWebPage';
export const UI_VERSION = `${process.env.UI_VERSION}`;
const TM1_PATH = '/api/orders';
const BASE_URL = isSalesWebPage() ? process.env.INBOUND_PCICONN_URL : TM1_PATH;
export const SALES_API_URL = `${BASE_URL}/sales-api`;
export const SIGNER_URL = `${SALES_API_URL}/signer`;
export const EVENTS_SERVICE_URL = `${SALES_API_URL}/events`;
export const SYSTEM_SERVICE_URL = `${SALES_API_URL}/system`;
export const POS_API_URL = `${BASE_URL}/pos-api`;
export const POS_GRAPHQL_URL = `${POS_API_URL}/graphql`;
export const SYSTEM_ID = {
    HOST: 'HST',
    ARCHTICS: 'ARX',
};
export const ERROR_MESSAGE_DELAY = 5000;
export const CART_HAS_EXPIRED_INVENTORY_MASK = '0x200001';
export const INVENTORY_ERROR = 'INVENTORY_ERROR';
export const TIMEOUT_DELAY = 120 * 60 * 1000;
export const HOST = 'HOST';
export const OPCODE = 'OPCODE';
export const SMS = 'SMS';
export const EMAIL = 'Email';
export const PRINT = 'Print';
export const currencyFormatOptions = {
    style: 'currency',
    currencyDisplay: 'symbol',
};
export const errorCodes = {
    OPCODE_LOCK: '184615124',
    PDG_FAULTY_CONNECTION: 'pdg.error.connection.closed',
};
export const DEFAULT_INVENTORY_TYPE_SELECTED = 'OPEN';
export const HOLD_INVENTORY_TYPE = 'HOLD';
export const STATUS = {
    OPEN: 'OPEN',
    DIST_OPEN: 'DIST-OPEN',
    HOLD: 'HOLD',
    INQUIRY: 'INQUIRY',
    SOLD: 'SOLD',
};
export const oidcUserStorageKey = `oidc.user:${process.env.USER_OAUTH_BASE_URL}/v1/authorize:0oaauy0z20G3VCSSr1d7`;
export const DEFAULT_HOST_LANGUAGE_NAME = 'ENGLISH';
export const DEFAULT_HOST_PERMISSIONS = {
    canRefund: false,
    canRefundPrinted: false,
    canUseTmdsPayment: false,
    canUsePdg: false,
    canUseCcManualEntryWithoutEmv: false,
    canLinkGlobalAccount: false,
    defaultSellClass: { id: '000000000000', name: DEFAULT_INVENTORY_TYPE_SELECTED },
    languageName: DEFAULT_HOST_LANGUAGE_NAME,
    mustAuthorizeCards: true,
    paymentMethods: [],
    sellClasses: [{ id: '000000000000', name: DEFAULT_INVENTORY_TYPE_SELECTED }],
};
export const FIELDS_TO_MASK = [
    'nameOnCard',
    'customerReceiptData',
    'merchantReceiptData',
    'token',
    'hostToken',
    'accesstoken',
];
export var AppName;
(function (AppName) {
    AppName["ORDERS"] = "Orders";
    AppName["SALES"] = "Sales";
})(AppName || (AppName = {}));
export const TM1_ORDERS_CUSTOM_EVENT = 'TM1_ORDERS_CUSTOM_EVENT';
export const TM1_ORDERS_DISPATCHED_EVENT = {
    USER_LOGOUT: 'USER_LOGOUT',
};
export const TM1ORDERS_CUSTOM_ELEMENT_TAG = 'tm1-orders';
