/* eslint-disable import/no-import-module-exports */
import TmdsClient from '@ticketmaster/tm1pos-tmds-client';
import { ErrorBoundary } from '@ticketmaster/tm1pos-web-shared/components/ErrorBoundary/ErrorBoundary';
import { RootDocumentContext } from '@ticketmaster/tm1pos-web-shared/context/root-document';
import {
  tmdsPaymentActor,
  TmdsPaymentsMachineEvents,
} from '@ticketmaster/tm1pos-web-shared/payment/tmds/tmdsPaymentService';
import { logClientToCloud } from '@ticketmaster/tm1pos-web-shared/store/actions';
import { LanguageProvider } from '@ticketmaster/tm1pos-web-shared/translations/index';
import '@tm1/design-system';
import ReactDom from 'react-dom';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { browserHistory, Router } from 'react-router';
import { OidcProvider } from 'redux-oidc';
import { SplunkRumAgent } from 'containers/App/components/splunk/splunk';
import ErrorPage from './containers/App/components/ErrorPage';
import { TMDS_PRINTER_ERROR_NOTIFICATION } from './containers/App/constants';
import App from './containers/App/index';
import createRoutes from './routes';
import { store } from './store';
import userManager from './utils/userManager';
import type { RouteConfig } from 'react-router';

import '@ticketmaster/tm1pos-web-shared/styles/main.scss';
import 'animate.css/animate.min.css';
import 'sanitize.css/sanitize.css';
import 'global.scss';

TmdsClient.configure({
  printLogs: 'debug',
  cloudLogger: (log: any) => store.dispatch(logClientToCloud(log)),
  printErrorCallback: (data: any) => store.dispatch({ type: TMDS_PRINTER_ERROR_NOTIFICATION, ...data }),
});

tmdsPaymentActor.send({ type: TmdsPaymentsMachineEvents.INIT, dispatch: store.dispatch });

const rootRoute: RouteConfig = {
  component: App,
  childRoutes: createRoutes(store),
};

const errorPage = (
  <LanguageProvider>
    <ErrorPage />
  </LanguageProvider>
);

const render = () => {
  ReactDom.render(
    <RootDocumentContext.Provider value={{ rootDocument: document }}>
      <ReduxStoreProvider store={store}>
        <ErrorBoundary errorPage={errorPage}>
          <SplunkRumAgent />
          <OidcProvider store={store} userManager={userManager}>
            <Router history={browserHistory} routes={rootRoute} />
          </OidcProvider>
        </ErrorBoundary>
      </ReduxStoreProvider>
    </RootDocumentContext.Provider>,
    document.querySelector('#tm1-sales-app'),
  );
};

if (module.hot) {
  module.hot.accept('./containers/App', () => {
    // Only in dev
    // eslint-disable-next-line @typescript-eslint/no-shadow,global-require, @typescript-eslint/no-var-requires
    const App = require('./containers/App/index').default;

    ReactDom.render(<App />, document.querySelector('#tm1-sales-app'));
  });
}

render();
