import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tm1Button, Tm1Dialog, Tm1DialogFooter } from '@tm1/design-system-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import appMessages from '../../messages';
import { messages } from './messages';
import './styles.scss';
var ErrorType;
(function (ErrorType) {
    ErrorType[ErrorType["PRINT"] = 0] = "PRINT";
    ErrorType[ErrorType["DELIVERY"] = 1] = "DELIVERY";
    ErrorType[ErrorType["ACCOUNT"] = 2] = "ACCOUNT";
    ErrorType[ErrorType["PRINT_AND_DELIVERY"] = 3] = "PRINT_AND_DELIVERY";
})(ErrorType || (ErrorType = {}));
const errorMessageByErrorCode = {
    'delivery.failed': messages.unableToGetInformationErrorMessage,
    TICKET_RENDER_FAILED: messages.unableToGenerateReceiptOrTicketsErrorMessage,
    'digital.delivery.failed': messages.technicalErrorMessage,
    'receipt.generation.failed': messages.unableToGenerateReceiptErrorMessage,
    'no.barcode': messages.noBarcodeDeliveryFailTipMessage,
    'send.tickets.failed': messages.unableToSendTicketErrorMessage,
    'send.receipt.failed': messages.unableToSendReceiptErrorMessage,
    'print.tickets.failed': messages.unableToGenerateReceiptOrTicketsErrorMessage,
};
const errorTypeByErrorCode = {
    'delivery.failed': ErrorType.DELIVERY,
    TICKET_RENDER_FAILED: ErrorType.PRINT,
    'digital.delivery.failed': ErrorType.DELIVERY,
    'update.account.failed': ErrorType.ACCOUNT,
    'receipt.generation.failed': ErrorType.PRINT,
    'no.barcode': ErrorType.DELIVERY,
    'send.tickets.failed': ErrorType.DELIVERY,
    'send.receipt.failed': ErrorType.DELIVERY,
    'print.tickets.failed': ErrorType.PRINT,
};
const modalTitleByErrorType = {
    [ErrorType.PRINT]: messages.printErrorModalTitle,
    [ErrorType.DELIVERY]: messages.deliveryErrorModalTitle,
    [ErrorType.ACCOUNT]: messages.orderPartialUpdateWarningModalTitle,
    [ErrorType.PRINT_AND_DELIVERY]: messages.deliveryAndPrintErrorModalTitle,
};
const mapPrinterError = (printerError) => {
    if (printerError && printerError.title && printerError.title.id && printerError.title.defaultMessage) {
        return { id: printerError.title.id, defaultMessage: printerError.title.defaultMessage.toString() };
    }
    return undefined;
};
const getErrorType = (errorCodes, printerError) => {
    let hasPrintError = false;
    let hasDeliveryError = false;
    let hasAccountError = false;
    if (printerError) {
        hasPrintError = true;
    }
    if (errorCodes) {
        errorCodes.forEach((errorCode) => {
            const errorType = errorTypeByErrorCode[errorCode];
            if (errorType === ErrorType.ACCOUNT) {
                hasAccountError = true;
            }
            if (errorType === ErrorType.DELIVERY) {
                hasDeliveryError = true;
            }
            if (errorType === ErrorType.PRINT) {
                hasPrintError = true;
            }
        });
    }
    if (hasAccountError) {
        return ErrorType.ACCOUNT;
    }
    if (hasDeliveryError && hasPrintError) {
        return ErrorType.PRINT_AND_DELIVERY;
    }
    if (hasPrintError) {
        return ErrorType.PRINT;
    }
    if (hasDeliveryError) {
        return ErrorType.DELIVERY;
    }
    return null;
};
const getErrorMessage = (errorCodes, errorType) => {
    const errorCode = errorCodes === null || errorCodes === void 0 ? void 0 : errorCodes.find((error) => errorTypeByErrorCode[error] === errorType);
    return errorCode ? errorMessageByErrorCode[errorCode] : messages.technicalErrorMessage;
};
export const DeliveryErrorModal = injectIntl(({ errorCodes, onClose, printerError }) => {
    const errorType = getErrorType(errorCodes, printerError);
    if (errorType === null) {
        return null;
    }
    const titleMessage = modalTitleByErrorType[errorType];
    const isAccountError = errorType === ErrorType.ACCOUNT;
    const showPrint = errorType === ErrorType.PRINT || errorType === ErrorType.PRINT_AND_DELIVERY;
    const showDelivery = errorType === ErrorType.DELIVERY || errorType === ErrorType.PRINT_AND_DELIVERY;
    const severity = isAccountError ? 'alert' : 'error';
    const printErrorMessage = mapPrinterError(printerError) || getErrorMessage(errorCodes, ErrorType.PRINT);
    const deliveryErrorMessage = getErrorMessage(errorCodes, ErrorType.DELIVERY);
    return (_jsxs(Tm1Dialog, { className: "delivery-error-modal", open: true, size: "medium", type: severity, backgroundOverlay: true, children: [_jsx("div", { className: "delivery-error-modal__header", slot: "dialog-header", children: _jsx(FormattedMessage, { ...titleMessage }) }), _jsx("div", { className: "delivery-error-modal__content", slot: "dialog-content", children: !isAccountError ? (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, { ...messages.errorModalTopContentMessage }), _jsxs("ul", { className: "delivery-error-modal__list", children: [showPrint ? (_jsxs("li", { children: [_jsx("strong", { children: _jsx(FormattedMessage, { ...messages.printListItem }) }), _jsx(FormattedMessage, { ...printErrorMessage })] })) : null, showDelivery ? (_jsxs("li", { children: [_jsx("strong", { children: _jsx(FormattedMessage, { ...messages.deliveryListItem }) }), _jsx(FormattedMessage, { ...deliveryErrorMessage })] })) : null] }), _jsx(FormattedMessage, { ...messages.errorModalBottomContentMessage })] })) : (_jsx(FormattedMessage, { ...messages.accountUpdateErrorMessage })) }), _jsx(Tm1DialogFooter, { slot: "footer", children: _jsx(Tm1Button, { slot: "actions", onClick: onClose, children: _jsx(FormattedMessage, { ...appMessages.Ok }) }) })] }));
});
