export const STORAGE_ARCHTICS_PERMISSIONS = 'sdr/archtics-permissions';
export const STORAGE_UNPAID_ORDER_ID = 'sdr/unpaid-order-id';
export const ARCHTICS = 'ARCHTICS';
export const HOST = 'HOST';
export const OPCODE = 'OPCODE';

export const PAYMENT_CATEGORY = {
  OTHER: 'OTH',
  CASH: 'CSH',
  CREDIT_CARD: 'CC',
  CHECK: 'CK',
  COMP: 'COMP',
};

export const TMDS_PRINTER_ERROR_NOTIFICATION = 'TMDS_PRINTER_ERROR_NOTIFICATION';
