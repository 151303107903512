import { call, put } from 'redux-saga/effects';
import { deliverTickets } from '../../../../services/sales-api-client/sales-api-shopping';
import { DELIVERY_ERROR, DO_DELIVERY_END } from './actions-constants';

export const getDeliveryConfirmation = (action, failed, response) => {
  let delivery;
  const orderConfirmation = action.order;
  const method = action.deliveryMethod;

  if (!failed) {
    delivery = action.deliveryRequest.recipient;
  }
  orderConfirmation.filesToPrint = response.data?.deliver;

  return {
    deliverySuccess: !failed,
    delivery,
    deliveryMethod: method,
    ...orderConfirmation,
  };
};

export function* handleDelivery(action) {
  let deliveryHasFailed;
  let response;

  try {
    response = yield call(deliverTickets, { ...action });
    deliveryHasFailed = yield call(processError, response, action.deliveryMethod);
  } catch (error) {
    deliveryHasFailed = true;
  }

  const deliveryConfirmation = yield call(getDeliveryConfirmation, action, deliveryHasFailed, response);
  yield put({ type: DO_DELIVERY_END, data: deliveryConfirmation });
}

export function* processError(response) {
  const hasErrors = !!response?.errors?.length;
  if (hasErrors) {
    const errors = response.errors.map((error) => error.extensions.code);
    yield put({ type: DELIVERY_ERROR, errors });
    return true;
  }
  return false;
}
