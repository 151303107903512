import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    deliveryAndPrintErrorModalTitle: {
        id: 'orders.App.deliveryAndPrintError',
        defaultMessage: 'Unable to Deliver and Print',
    },
    deliveryErrorModalTitle: {
        id: 'orders.App.deliveryError',
        defaultMessage: 'Unable to Deliver',
    },
    printErrorModalTitle: {
        id: 'orders.App.printError',
        defaultMessage: 'Unable to Print',
    },
    orderPartialUpdateWarningModalTitle: {
        id: 'orders.App.orderPartialUpdateWarning',
        defaultMessage: 'Order Partially Updated',
    },
    errorModalTopContentMessage: {
        id: 'orders.App.errorModalTopContent',
        defaultMessage: 'The payment was successfully processed but our system encountered the following error:',
    },
    errorModalBottomContentMessage: {
        id: 'orders.App.errorModalBottomContent',
        defaultMessage: 'Try again or if the problem(s) persists, contact your Ticketmaster representative for assistance.',
    },
    deliveryListItem: {
        id: 'orders.App.deliveryListItem',
        defaultMessage: 'Delivery: ',
    },
    printListItem: {
        id: 'orders.App.printListItem',
        defaultMessage: 'Print: ',
    },
    deliveryInformationRetrivalErrorMessage: {
        id: 'orders.App.deliveryInformationRetrivalError',
        defaultMessage: 'Unable to deliver due to an issue retrieving required information.',
    },
    printInformationRetrivalErrorMessage: {
        id: 'orders.App.printInformationRetrivalError',
        defaultMessage: 'Unable to print due to an issue retrieving required information.',
    },
    unableToGetInformationErrorMessage: {
        id: 'orders.App.unableToGetInformationError',
        defaultMessage: 'Unable to deliver due to an issue retrieving required information.',
    },
    unableToGenerateReceiptOrTicketsErrorMessage: {
        id: 'orders.App.unableToGenerateReceiptOrTicketsError',
        defaultMessage: 'Unable to generate receipt and/or tickets.',
    },
    unableToGenerateReceiptErrorMessage: {
        id: 'orders.App.unableToGenerateReceipt',
        defaultMessage: 'Unable to generate receipt.',
    },
    unableToSendReceiptErrorMessage: {
        id: 'orders.App.unableToSendReceipt',
        defaultMessage: 'Unable to send receipt.',
    },
    unableToSendTicketErrorMessage: {
        id: 'orders.App.unableToSendTicket',
        defaultMessage: 'Unable to send ticket.',
    },
    technicalErrorMessage: {
        id: 'orders.App.technicalError',
        defaultMessage: 'Unable to deliver due to a technical error.',
    },
    accountUpdateErrorMessage: {
        id: 'orders.App.accountUpdateError',
        defaultMessage: 'The payment was successfully processed, and the tickets were delivered, but our system encountered an error and some information couldn’t be updated.',
    },
    noBarcodeDeliveryFailTipMessage: {
        id: 'sales.errors.noBarcodeDeliveryFailTipMessage',
        defaultMessage: 'The tickets could not be delivered because the event has not been barcoded. Please check the event configuration.',
    },
});
