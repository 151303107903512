import TmdsClient from '@ticketmaster/tm1pos-tmds-client';
import {
  FINISHED_USER_DATA_RETRIEVAL,
  SET_INVENTORY_TYPE,
} from '@ticketmaster/tm1pos-web-shared/store/actions-constants';
import { checkDefined } from '@ticketmaster/tm1pos-web-shared/utils';
import { isEventPath } from '@ticketmaster/tm1pos-web-shared/utils/conditionals';
import storage from '@ticketmaster/tm1pos-web-shared/utils/storage/storageService';
import { LOCATION_CHANGE } from 'connected-react-router';
import { fromJS } from 'immutable';
import { SetEventFeesErrorType } from '../../services/sales-api-client/model/set-cart-event-fees-response';
import {
  ACKNOWLEDGE_DELIVERY_ERROR,
  CANCEL_CHECKOUT,
  DELIVERY_ERROR,
  DO_DELIVERY_END,
  FETCH_CHECKOUT,
} from '../Checkout/components/Form/actions-constants';
import {
  CHECKOUT_EVENT_ID_SET,
  EVENT_ID_SET,
  FETCH_BEST_AVAILABLE_SEATS,
  PLACE_TO_RESERVE_DESELECT,
  PLACE_TO_RESERVE_FETCH,
  PLACE_TO_UNRESERVE_FETCH,
} from '../EventDetailPage/constants';
import {
  CLEAR_CART_FAILED,
  CLEAR_CART_SUCCESS,
  CLOSE_FEEDBACK_MODAL,
  FETCH_FEEDBACK_CONTACTS,
  FETCH_SUBMIT_FEEDBACK,
  PAYMENT_CERTIFICATE_FAILED,
  PAYMENT_CERTIFICATE_SUCCESS,
  QUALIFIED_HOLDS_TOGGLE,
  SET_DEFAULT_INVENTORY_TYPE,
  START_LOGIN,
  TOGGLE_CHECKOUT,
} from './actions-constants';
import {
  setEventFeesError,
  setEventFeesLoading,
  setEventFeesSuccess,
  toggleEditOtherChargesModal,
} from './event-fees-actions';
import { selectEventSeatBlocks } from './selectors';

export const initialState = fromJS({
  isLoginStarted: false,
  additionalUserDataLoaded: false,
  showCart: storage.getItem('showCart') || false,
  cart: null,
  userRefundPermissions: null,
  successOrder: null,
  paymentCertificate: null,
  selectedPrinter: null,
  isLoadingCheckout: false,
  printerQueue: null,
  feedbackContacts: {},
  isLoadingFeedbackSubmit: false,
  isLoadingSetEventFees: false,
  showEditOtherChargesModal: false,
  showEditOtherChargesErrorBanner: false,
  showCartExpiredErrorModal: false,
  isFeedbackSubmitted: false,
  localPrinterCapabilities: null,
  activeLocalPrintJobs: null,
  tmdsPrinters: null,
  localPrinterError: null,
  deselectingSeat: false,
  removingSeatPlaceId: null,
  currentEventId: null,
  rowCart: null,
  checkoutCart: null,
  selectedInventoryType: null,
  defaultInventoryType: null,
  printServiceInUse: null,
  paymentDevice: null,
  paymentDeviceData: null,
  isEMVCheckoutError: false,
  showEmvCancel: false,
  qualifiedHoldsToggle: false,
  deliveryErrors: null,
});
// eslint-disable-next-line sonarjs/cognitive-complexity
function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (action.payload && !isEventPath(action.payload.pathname)) {
        storage.setItem('showCart', false);
        return state.set('showCart', false);
      }
      return state;
    }
    case START_LOGIN: {
      return state.set('isLoginStarted', true);
    }
    case TOGGLE_CHECKOUT: {
      const toggle = !state.get('showCart');
      storage.setItem('showCart', toggle);
      return state.set('showCart', toggle).set('successOrder', null);
    }
    case FINISHED_USER_DATA_RETRIEVAL:
      return state.set('additionalUserDataLoaded', true);
    case FETCH_BEST_AVAILABLE_SEATS.SUCCESS:
    case PLACE_TO_UNRESERVE_FETCH.SUCCESS:
    case PLACE_TO_RESERVE_FETCH.SUCCESS: {
      if (window.location.pathname === '/') {
        return state;
      }
      const actionData = action.data;
      const eventSeatBlocks = selectEventSeatBlocks(actionData.items);
      storage.setItem('rowCart', action.rowCart);
      storage.setItem('checkoutCart', eventSeatBlocks);
      return state
        .set('removingSeatPlaceId', null)
        .set('deselectingSeat', false)
        .set('rowCart', action.rowCart)
        .set('cart', actionData)
        .set('checkoutCart', eventSeatBlocks);
    }
    case CHECKOUT_EVENT_ID_SET:
    case EVENT_ID_SET: {
      return state.set('currentEventId', action.eventId);
    }
    case FETCH_CHECKOUT.SUCCESS: {
      let newState = state;
      if (checkDefined(action, ['data', 'totals'])) {
        const cart = { ...newState.get('cart') };
        cart.totals = action.data.totals;
        newState = newState.set('cart', cart);
      }
      TmdsClient.setLoadingCheckout(false);
      return newState
        .set('successOrder', action.data)
        .set('selectedInventoryType', newState.get('defaultInventoryType'));
    }
    case DO_DELIVERY_END: {
      const showCart = state.get('showCart');
      if (!showCart) {
        return state;
      }

      const successOrder = state.get('successOrder');
      const successOrderWithDelivery = {
        ...successOrder,
        deliverySuccess: action.data.deliverySuccess,
        deliveryMethod: action.data.deliveryMethod,
        delivery: action.data.delivery,
      };
      return state.set('successOrder', successOrderWithDelivery);
    }
    case FETCH_CHECKOUT.LOADING:
      TmdsClient.setLoadingCheckout(true);
      return state;
    case CANCEL_CHECKOUT:
    case FETCH_CHECKOUT.ERROR:
      TmdsClient.setLoadingCheckout(false);
      return state;
    case PAYMENT_CERTIFICATE_FAILED:
      return state.set('paymentCertificate', null);
    case PAYMENT_CERTIFICATE_SUCCESS:
      return state.set('paymentCertificate', action.certificate);
    case CLEAR_CART_SUCCESS:
    case CLEAR_CART_FAILED:
      storage.setItem('rowCart', null);
      return state
        .set('cart', null)
        .set('deselectingSeat', false)
        .set('selectedInventoryType', state.get('defaultInventoryType'))
        .set('rowCart', null);
    case FETCH_FEEDBACK_CONTACTS.SUCCESS:
      return state.set('feedbackContacts', action.feedbackContacts);
    case FETCH_FEEDBACK_CONTACTS.ERROR:
      return state.set('feedbackContacts', {});
    case FETCH_SUBMIT_FEEDBACK.LOADING:
      return state.set('isLoadingFeedbackSubmit', true);
    case FETCH_SUBMIT_FEEDBACK.ERROR:
      return state.set('isFeedbackSubmitted', false).set('isLoadingFeedbackSubmit', false);
    case FETCH_SUBMIT_FEEDBACK.SUCCESS:
      return state.set('isFeedbackSubmitted', true).set('isLoadingFeedbackSubmit', false);
    case CLOSE_FEEDBACK_MODAL:
      return state.set('isFeedbackSubmitted', false);
    case PLACE_TO_RESERVE_DESELECT:
      return state.set('deselectingSeat', true).set('removingSeatPlaceId', action.place.placeId);
    case PLACE_TO_UNRESERVE_FETCH.ERROR:
      return state.set('deselectingSeat', false);
    case SET_DEFAULT_INVENTORY_TYPE:
      return state.set('defaultInventoryType', action.inventoryType);
    case SET_INVENTORY_TYPE:
      return state.set('selectedInventoryType', action.inventoryType);
    case QUALIFIED_HOLDS_TOGGLE:
      return state.set('qualifiedHoldsToggle', action.isActive);
    case toggleEditOtherChargesModal.type:
      return state
        .set('showEditOtherChargesModal', !state.get('showEditOtherChargesModal'))
        .set('showEditOtherChargesErrorBanner', false);
    case setEventFeesLoading.type:
      return state.set('isLoadingSetEventFees', true).set('showEditOtherChargesErrorBanner', false);
    case setEventFeesSuccess.type:
      return state
        .set('cart', action.payload)
        .set('isLoadingSetEventFees', false)
        .set('showEditOtherChargesModal', false);
    case setEventFeesError.type:
      if (action.payload.error === SetEventFeesErrorType.CART_ID_INVALID_OR_EXPIRED) {
        return state.set('isLoadingSetEventFees', false).set('showCartExpiredErrorModal', true);
      }

      return state.set('isLoadingSetEventFees', false).set('showEditOtherChargesErrorBanner', true);
    case DELIVERY_ERROR:
      return state.set('deliveryErrors', action.errors);
    case ACKNOWLEDGE_DELIVERY_ERROR:
      return state.set('deliveryErrors', null);
    default:
      return state;
  }
}

export default appReducer;
