import { DeliveryErrorModal as SharedDeliveryErrorModal } from '@ticketmaster/tm1pos-web-shared/components/DeliveryErrorModal/DeliveryErrorModal';
import { selectPrinterError } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../../../../../store/hooks';
import { selectDeliveryErrors } from '../../../../../App/app-selectors';
import { ACKNOWLEDGE_DELIVERY_ERROR } from '../../actions-constants';

const DeliveryErrorModalConnected = () => {
  const dispatch = useDispatch();
  const errors = useSelector(selectDeliveryErrors);
  const printerError = useAppSelector(selectPrinterError);

  const handleClose = () => {
    dispatch({ type: ACKNOWLEDGE_DELIVERY_ERROR });
  };

  return (
    <> {errors && <SharedDeliveryErrorModal onClose={handleClose} errorCodes={errors} printerError={printerError} />}</>
  );
};

export const DeliveryErrorModal = DeliveryErrorModalConnected;
